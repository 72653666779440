import MapTemp from '@/components/map/MapOne.vue';
import NavBottom from '@/components/menu/NavBottom.vue';
import { comMethods } from '@/components/menu/com.js';
export default {
  props: {},
  data() {
    return {
      themeColor: '',
      lang: 2
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        this.lang = val;
      }
    }
  },
  computed: {},
  components: {
    MapTemp,
    NavBottom
  },
  mixins: [comMethods],
  created() {
    this.$store.commit('SET_MENU', 3);
  },
  mounted() {},
  methods: {}
};