import ContentTwo from '@/components/content/ContentTwo.vue';
export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {
    ContentTwo
  },
  created() {
    this.$store.commit('SET_MENU', 1);
  },
  mounted() {},
  beforeRouteLeave() {},
  methods: {}
};