import BannerSwiperTwo from '@/components/banner/BannerSwiperTwo.vue';
import { comMethods } from '@/components/menu/com.js';
// import MapOne from '@/components/map/MapOne.vue'
export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {
    BannerSwiperTwo
  },
  mixins: [comMethods],
  created() {
    this.$store.commit('SET_MENU', 1);
  },
  mounted() {},
  beforeRouteLeave() {},
  methods: {}
};