import AMapLoader from '@amap/amap-jsapi-loader';
import { shallowRef } from '@vue/reactivity';
export default {
  setup() {
    const map = shallowRef(null);
    return {
      map
    };
  },
  props: {},
  data() {
    return {
      lang: 2
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        this.lang = val;
        // this.setLoad()
      }
    }
  },

  computed: {},
  components: {},
  created() {
    this.setInit();
  },
  mounted() {
    this.setLoad();
  },
  methods: {
    setInit() {
      window._AMapSecurityConfig = {
        securityJsCode: 'fe78f17b78fc508ba387d8ef033aeb68'
      };
    },
    setLoad() {
      AMapLoader.load({
        key: 'acb80d79d59b440169061aa9a6fb2d58',
        // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0',
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("container", {
          //设置地图容器id
          mapStyle: 'amap://styles/dark',
          viewMode: '2D',
          //是否为3D地图模式
          zoom: 17,
          //初始化地图级别
          center: [114.064309, 22.611184] //初始化地图中心点位置
          // lang:'en'
        });

        // 创建一个 Marker 实例：
        const marker = new AMap.Marker({
          position: new AMap.LngLat(114.064309, 22.611184),
          // 经纬度对象，也可以是经纬度构成的一维数组[114.064309,22.611184]
          title: 'BM',
          content: '<i class="iconfont icon-location-full" style="color: #ffffff;font-size:40px;"></i>'
          // label: {
          //   content: '333'
          // }
        });

        // 将创建的点标记添加到已有的地图实例：
        this.map.add(marker);
      }).catch(e => {
        console.log(e);
      });
    }
  }
};