import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6a1dfc2d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "com-contentOne-itemTwo-div"
};
const _hoisted_2 = {
  class: "com-contentOne-itemTwo-bottom-text"
};
const _hoisted_3 = {
  class: "com-contentOne-itemTwo-con"
};
const _hoisted_4 = {
  class: "com-contentOne-itemTwo-abouts-div"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "com-contentOne-itemTwo-abouts-close-text"
};
const _hoisted_9 = {
  class: "com-contentOne-itemTwo-abouts-close-icon-div"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "com-contentOne-itemTwo-box",
    style: _normalizeStyle({
      height: `${$props.imgHeight}px`
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.detail.fullName), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass({
      'z-com-content-itemThree-main': true,
      'z-com-content-itemThree-main-hide': $data.show
    })
  }, [_createElementVNode("img", {
    class: _normalizeClass({
      'z-img img-1': true,
      'img-1-active': $data.show
    }),
    src: $props.slotProps.item.main[0]
  }, null, 10, _hoisted_5), _createElementVNode("img", {
    class: _normalizeClass({
      'z-img img-2': true,
      'img-2-active': $data.show
    }),
    src: $props.slotProps.item.main[1]
  }, null, 10, _hoisted_6), _createElementVNode("img", {
    class: _normalizeClass({
      'z-img img-3': true,
      'img-3-active': $data.show
    }),
    src: $props.slotProps.item.main[2]
  }, null, 10, _hoisted_7)], 2), _createElementVNode("div", {
    class: _normalizeClass(["com-contentOne-itemTwo-abouts-close-div", {
      'com-contentOne-itemTwo-abouts-close-div-show': $data.show
    }])
  }, [_createElementVNode("div", _hoisted_8, _toDisplayString($props.detail.text), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("i", {
    class: "iconfont icon-guanbi1 com-contentOne-itemTwo-abouts-close-icon",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.toClose && $options.toClose(...args))
  })])], 2)])])])], 4);
}