import ContentOne from '@/components/content/ContentOne/OneIndex.vue';
// import MapOne from '@/components/map/MapOne.vue'
export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {
    ContentOne
  },
  created() {
    this.$store.commit('SET_MENU', 1);
  },
  mounted() {},
  beforeRouteLeave() {},
  methods: {}
};