export default {
  props: {
    imgHeight: {
      default: 1000
    },
    detail: {
      default() {
        return {};
      }
    },
    slotProps: {
      default() {
        return {};
      }
    },
    activeIndex: {
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    activeIndex(val) {
      if (val == 1) {
        this.show = true;
      } else {
        this.show = false;
      }
    }
  },
  computed: {},
  components: {},
  created() {},
  mounted() {},
  methods: {
    toShow() {},
    toClose() {
      this.show = false;
      setTimeout(() => {
        this.$emit('toClose', 0);
      }, 800);
    }
  }
};