import { ref } from 'vue';
// Import Swiper Vue.js components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { PRUDUCTS } from '@/components/language/text.js';
// Import Swiper styles
import 'swiper/css';
export default {
  props: {
    dataArr: {
      default() {
        return [];
      }
    },
    products: {
      default() {
        return [];
      }
    },
    itemHeight: {
      default: 1000
    },
    hasBtn: {
      default: true
    }
  },
  data() {
    return {
      PRUDUCTS,
      active: 0,
      swiper: null,
      isPreHover: false,
      isNextHover: false,
      isNextClick: false,
      isPreClick: false
    };
  },
  computed: {
    isFirst() {
      return this.active === 0;
    },
    isLast() {
      return this.active === this.dataArr.length - 1;
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const navigation = ref({
      nextEl: '.com-swiperThree-next-div',
      prevEl: '.com-swiperThree-next-div'
    });
    return {
      modules: [Navigation],
      navigation
    };
  },
  mounted() {
    this.active = Number(this.$route.query.color) || 0;
    this.slideTo(this.active);
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange(swiper) {
      console.log('activeIndex', swiper.activeIndex);
      this.active = swiper.activeIndex;
      this.$emit('onSlideChange', swiper.activeIndex);
    },
    toPre() {},
    toNext() {},
    slideTo(index) {
      this.swiper.slideTo(index);
    },
    addBtnMoseEnter(type, bool) {
      if (bool) return false;
      if (type === 1) {
        this.isPreHover = true;
      } else {
        this.isNextHover = true;
      }
      this.$emit('addBtnMoseEnter', type);
    },
    addBtnMoseLeave(type) {
      if (type === 1) {
        this.isPreHover = false;
      } else {
        this.isNextHover = false;
      }
      this.$emit('addBtnMoseLeave', type);
    },
    toSlidePrev() {
      if (this.isFirst) return;
      this.swiper.slidePrev();
      this.isPreClick = true;
      setTimeout(() => {
        this.isPreClick = false;
      }, 500);
    },
    toSlideNext() {
      if (this.isLast) return;
      this.swiper.slideNext();
      this.isNextClick = true;
      setTimeout(() => {
        this.isNextClick = false;
      }, 500);
    }
  }
};