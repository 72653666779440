import "core-js/modules/es.array.push.js";
import { MENULANG } from '@/components/language/text.js';
export default {
  data() {
    return {
      defaultActive: 0,
      lang: 2,
      tabs: [],
      menuName: false,
      showList: false,
      timeOut: null,
      active: ''
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        console.error('lang', val);
        this.lang = val;
        this.tabs = MENULANG[this.lang];
      }
    },
    '$store.state.menu': {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('$store.state.menu', val);
        this.defaultActive = val;
        this.active = this.tabs[this.defaultActive].name;
      }
    },
    '$route.name': {
      immediate: true,
      handler() {
        if (document.getElementById('menu')) {
          document.getElementById('menu').classList.remove('clicked');
          this.menuName = false;
          this.showList = false;
        }
      }
    }
  },
  computed: {},
  components: {},
  created() {
    const lang = window.sessionStorage.getItem('lang');
    this.lang = Number(lang) || 1; // 默认语言设置
    this.$store.commit('SET_LANG', this.lang);
  },
  mounted() {
    console.log('this.active', this.active);
    console.log('this.$route', this.$router.name);
    // this.handMenuInfo(this.$router)
  },

  methods: {
    toShowAll() {
      var box = document.getElementById('menu');
      box.classList.toggle('clicked');
      clearTimeout(this.timeOut);
      this.timeOut = null;
      const bool = box.classList.value.includes('clicked');
      this.menuName = bool;
      console.log('bool', bool);
      this.timeOut = setTimeout(() => {
        this.showList = bool;
      }, bool ? 600 : 0);
    },
    toChange({
      name
    }) {
      if (this.tabs[this.defaultActive].name === name) {
        return false;
      }
      if (name != 'product') {
        document.getElementById('menu').classList.toggle('clicked');
        this.menuName = false;
        this.showList = false;
        this.$router.push({
          name
        });
      }
    },
    toDetail({
      name,
      value
    }) {
      if (this.$route.query.value == value) return false;
      document.getElementById('menu').classList.toggle('clicked');
      this.menuName = false;
      this.showList = false;
      this.$router.push({
        name: 'product',
        query: {
          value: value,
          name: encodeURIComponent(name)
        }
      });
    },
    toChangeLan() {
      if (this.lang === 1) {
        this.lang = 2;
      } else {
        this.lang = 1;
      }
      console.log('this.lang', this.lang);
      window.sessionStorage.setItem('lang', this.lang);
      this.$store.commit('SET_LANG', this.lang);
    }
  }
};