import "core-js/modules/es.array.push.js";
import { MENULANG } from '@/components/language/text.js';
export default {
  props: {},
  data() {
    return {
      nav: [{
        name: '首页'
      }, {
        name: '产品中心',
        child: [{
          name: 'X12 Pro'
        }, {
          name: 'X12 Ultra'
        }, {
          name: 'E12 Ultra'
        }, {
          name: 'G12 Elite'
        }, {
          name: 'E16'
        }, {
          name: 'E18'
        }, {
          name: 'Jetpods'
        }]
      }, {
        name: '关于我们',
        child: [{
          name: '公司简介'
        }, {
          name: '文化'
        }]
      }, {
        name: '技术支持',
        child: [{
          name: '联系我们'
        }]
      }],
      lang: 2,
      tabs: [],
      defaultActive: 0,
      active: ''
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        console.error('lang', val);
        this.lang = val;
        this.tabs = MENULANG[this.lang];
        console.log(this.tabs);
      }
    },
    '$store.state.menu': {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('$store.state.menu', val);
        this.defaultActive = val;
        this.active = this.tabs[this.defaultActive].name;
      }
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toChange({
      name
    }) {
      if (this.tabs[this.defaultActive].name === name) {
        return false;
      }
      if (name !== 'product') {
        this.$router.push({
          name
        });
      }
    },
    toDetail({
      name,
      value
    }) {
      if (this.$route.query.value == value) return false;
      this.$router.push({
        name: 'product',
        query: {
          value: value,
          name: encodeURIComponent(name)
        }
      });
    }
  }
};