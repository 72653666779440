export default {
  props: {
    imgHeight: {
      default: 1000
    },
    detail: {
      default() {
        return {};
      }
    },
    slotProps: {
      default() {
        return {};
      }
    },
    activeIndex: {
      default: ''
    }
  },
  data() {
    return {
      show: false,
      componentKey: null
    };
  },
  watch: {
    activeIndex(val) {
      if (val == 2) {
        this.show = true;
      } else {
        this.show = false;
      }
    }
  },
  computed: {},
  components: {},
  created() {},
  mounted() {},
  methods: {
    toDetail(item) {
      console.log('@@@@', item);
      // this.$router.push({
      //   name: 'productThree',
      //   query: {
      //     value: value,
      //     name: encodeURIComponent(name)
      //   }
      // })
    },

    toShow() {},
    toClose() {
      this.show = false;
      setTimeout(() => {
        this.$emit('toClose', 0);
      }, 800);
    }
    // toggleComponent() {
    //   this.componentKey = `dongtai${Math.random()}`;
    // }
  }
};