import SwiperThree from '@/components/swiper/SwiperThree.vue';
import SwiperFive from '@/components/swiper/SwiperFive.vue';
import ItemOne from '@/components/content/ContentOne/ItemOne.vue';
import ItemTwo from '@/components/content/ContentOne/ItemTwo.vue';
import ItemThree from '@/components/content/ContentOne/ItemThree.vue';
import { PRUDUCTS, PRODUCT_DETAIL } from '@/components/language/text.js';
export default {
  props: {},
  data() {
    return {
      imgHeight: 1000,
      active: 0,
      activeFive: 0,
      componentName: ['ItemOne', 'ItemTwo', 'ItemThree'],
      products: [],
      dataArr: [
        // {
        //   title: '1',
        //   items: [
        //     {
        //       title: 'one',
        //       src: require('@/assets/images/three/bg.png')
        //     },
        //     {
        //       title: 'two',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     },{
        //       title: 'three',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     }
        //   ]
        // },
        // {
        //   title: '2',
        //   items: [
        //     {
        //       title: 'one',
        //       src: require('@/assets/images/three/bg.png')
        //     },
        //     {
        //       title: 'two',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     },{
        //       title: 'three',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     }
        //   ]
        // },
        // {
        //   title: '3',
        //   items: [
        //     {
        //       title: 'one',
        //       src: require('@/assets/images/three/bg.png')
        //     },
        //     {
        //       title: 'two',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     },{
        //       title: 'three',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     }
        //   ]
        // },
        // {
        //   title: '4',
        //   items: [
        //     {
        //       title: 'one',
        //       src: require('@/assets/images/three/bg.png')
        //     },
        //     {
        //       title: 'two',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     },{
        //       title: 'three',
        //       style: 1,
        //       bg: require('@/assets/images/three/23.png'),
        //       src: require('@/assets/images/three/21.png'),
        //       imgOne: require('@/assets/images/three/22.png'),
        //       imgTwo: require('@/assets/images/three/22.png'),
        //       summary: 'Born So\nDestined\nTo Be\nDifferent'
        //     }
        //   ]
        // }
      ],
      randomLetters: ['AAAA', 'AAAA', 'AAAA']
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        const products = PRUDUCTS[val][this.$route.query.value];
        console.error('products', products);
        const detailArr = PRODUCT_DETAIL[val][products.name];
        this.dataArr = detailArr;
        this.products = products;
      }
    }
  },
  computed: {},
  components: {
    SwiperThree,
    SwiperFive,
    ItemOne,
    ItemTwo,
    ItemThree
  },
  created() {},
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight);
  },
  beforeRouteLeave() {
    window.removeEventListener("resize", this.setHeight);
  },
  methods: {
    generateRandomLetters() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    setHeight() {
      this.imgHeight = document.documentElement.clientHeight;
    },
    sliceBtnMoseEnter(type) {
      this.$refs[`ItemOne_${this.active}`].sliceBtnMoseEnter(type);
    },
    sliceBtnMoseLeave(type) {
      this.$refs[`ItemOne_${this.active}`].sliceBtnMoseLeave(type);
    },
    onSlideChange(index) {
      this.activeFive = 0;
      setTimeout(() => {
        this.active = index;
        this.toChangeSlideFive(0);
      }, 100);
    },
    onSlideFiveChange(index) {
      this.activeFive = index;
    },
    toClose(index) {
      this.$refs[`swiperfive_${this.active}`].slideTo(index);
    },
    toChangeSlideFive(index) {
      this.randomLetters[index] = this.generateRandomLetters();
      setTimeout(() => {
        this.$refs[`swiperfive_${this.active}`].slideTo(index);
      }, 100);
    }
  }
};