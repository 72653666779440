/*
 * @author: weichuanjia
 * @Date: 2023-06-12 11:23:59
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/main.css'
import '@/assets/iconfont/iconfont.css'
import '@vant/touch-emulator'

import { Lazyload } from 'vant'
router.afterEach(() => {
  // 检查查询参数的变化
  // if (to.name == from.name && to.query !== from.query) {
  //   // 重新加载数据的操作
  //   window.location.reload()
  // }
})

document.title = '魔宴-SABBAT'
createApp(App).use(router).use(Lazyload, {
  lazyComponent: true,
}).use(store).mount('#app')
