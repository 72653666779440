import BannerSwiper from '@/components/banner/BannerSwiper.vue';
import { comMethods } from '@/components/menu/com.js';
export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {
    BannerSwiper
  },
  mixins: [comMethods],
  created() {
    this.$store.commit('SET_MENU', 0);
  },
  mounted() {
    console.log('this.$route.name', this.$route.name);
  },
  beforeRouteLeave() {},
  methods: {}
};