import "core-js/modules/es.array.push.js";
import '@vant/touch-emulator';
import SwiperTwo from '@/components/swiper/SwiperTwo.vue';
import { PRUDUCTS, PRODUCT_DETAIL } from '@/components/language/text.js';
export default {
  props: {
    defTabActive: {
      default: 0
    }
  },
  data() {
    return {
      imgHeight: 'auto',
      tabActive: 0,
      showText: false,
      actives: [],
      products: [],
      dataArr: [],
      //PRODUCT_DETAIL
      imgIsScale: false,
      lang: this.$store.state.lang
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        this.lang = val;
        this.products = PRUDUCTS[val];
        this.actives = new Array(this.products.length).fill(2);
        this.dataArr = PRODUCT_DETAIL[val];
      }
    },
    '$route.query.value': {
      deep: true,
      handler(val) {
        this.tabActive = Number(val) || 0;
      }
    }
  },
  computed: {},
  components: {
    SwiperTwo
  },
  created() {
    this.tabActive = Number(this.defTabActive);
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight);
  },
  beforeRouteLeave() {
    window.removeEventListener("resize", this.setHeight);
  },
  methods: {
    setHeight() {
      this.imgHeight = document.documentElement.clientHeight;
    },
    slideChangeTransitionStart() {
      this.showText = false;
      // console.log('start', index)
    },

    slideChangeTransitionEnd(index, idx) {
      this.actives[index] = idx;
      this.showText = true;
      console.log('end', index);
    },
    handleMouseEnter() {
      this.imgIsScale = true;
      // console.log(document.querySelector(`#image_${this.tabActive}_${this.active}`))
    },

    handleMouseLeave() {
      this.imgIsScale = false;
    },
    toFeature(index) {
      this.$router.push({
        name: 'productTwo',
        query: {
          value: index,
          color: this.actives[index]
        }
      });
    },
    toDetail(index) {
      this.$router.push({
        name: 'productThree',
        query: {
          value: index
        }
      });
    },
    slidesPerViewFun(num) {
      if (num > 3) {
        return 5;
      } else {
        return 4;
      }
    },
    initialSlideFun(num) {
      const middleIndex = Math.floor(num / 2);
      return middleIndex;
    }
  }
};