/*
 * @author: weichuanjia
 * @Date: 2023-06-12 11:23:59
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Detail from '@/views/detail/DetailOne.vue'
import Product from '@/views/product/ProductOne.vue'
import ProductTwo from '@/views/product/ProductTwo.vue'
import ProductThree from '@/views/product/ProductThree.vue'
import AboutTemp from '@/views/about/AboutOne.vue'
import MapTemp from '@/views/support/SupportOne.vue'

const routes = [
  {
    path: '/',
    name: 'detail',
    component: Detail
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/productTwo',
    name: 'productTwo',
    component: ProductTwo
  },
  {
    path: '/productThree',
    name: 'productThree',
    component: ProductThree
  },
  {
    path: '/about',
    name: 'about',
    component: AboutTemp
  },
  {
    path: '/support',
    name: 'support',
    component: MapTemp
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
