import "core-js/modules/es.array.push.js";
import SwiperOne from '@/components/swiper/SwiperOne.vue';
import { PRUDUCTS } from '@/components/language/text.js';
export default {
  props: {},
  data() {
    return {
      imgHeight: 'auto',
      dataArr: []
    };
  },
  watch: {
    '$store.state.lang': {
      deep: true,
      immediate: true,
      handler(val) {
        this.dataArr = PRUDUCTS[val];
        console.log(this.dataArr);
      }
    }
  },
  computed: {},
  components: {
    SwiperOne
  },
  created() {},
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight);
  },
  beforeRouteLeave() {
    window.removeEventListener("resize", this.setHeight);
  },
  methods: {
    setHeight() {
      this.imgHeight = document.documentElement.clientHeight;
    },
    toDetail({
      value,
      name
    }) {
      console.log(value, name);
      this.$router.push({
        name: 'product',
        query: {
          value: value,
          name: encodeURIComponent(name)
        }
      });
    }
  }
};