// Import Swiper Vue.js components
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
export default {
  props: {
    dataArr: {
      default() {
        return [];
      }
    },
    itemHeight: {
      default: 1000
    }
  },
  data() {
    return {
      swiper: null,
      active: 0
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [EffectFade]
    };
  },
  mounted() {
    console.log(this.dataArr);
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange(swiper) {
      this.active = swiper.activeIndex;
      this.$emit('onSlideChange', this.active);
    },
    toPre() {},
    slideTo(index) {
      console.log(index);
      this.swiper.slideTo(index);
    },
    toNext() {}
  }
};