import { ref } from 'vue';
// Import Swiper Vue.js components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
export default {
  props: {
    dataArr: {
      default() {
        return [];
      }
    },
    slidesPerView: {
      default: 5
    },
    initialSlide: {
      default: 2
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    const navigation = ref({
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    });
    // const onSlideChange = (item) => {
    //   console.log('slide change',item);
    // };
    return {
      modules: [Navigation],
      // onSwiper,
      // onSlideChange,
      navigation
    };
  },
  methods: {
    toPre() {},
    toNext() {},
    onSwiper() {},
    onSlideChange(swiper) {
      this.$emit('onSlideChange', swiper.activeIndex);
    },
    slideChangeTransitionStart(swiper) {
      this.$emit('slideChangeTransitionStart', swiper.activeIndex);
    },
    slideChangeTransitionEnd(swiper) {
      this.$emit('slideChangeTransitionEnd', swiper.activeIndex);
    }
  }
};