import { ref } from 'vue';
// Import Swiper Vue.js components
import { Navigation, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
export default {
  props: {
    dataArr: {
      default() {
        return [];
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const onSwiper = () => {
      // console.log(swiper);
    };
    const navigation = ref({
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    });
    const onSlideChange = () => {
      // console.log('slide change',item);
    };
    return {
      modules: [Navigation, Autoplay, EffectFade],
      onSwiper,
      onSlideChange,
      navigation
    };
  },
  methods: {
    toPre() {},
    toNext() {}
  }
};