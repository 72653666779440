import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b2b74aa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "com-contentOne-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_five = _resolveComponent("swiper-five");
  const _component_swiper_three = _resolveComponent("swiper-three");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_swiper_three, {
    dataArr: $data.dataArr,
    products: $data.products,
    itemHeight: $data.imgHeight,
    hasBtn: $data.activeFive === 0,
    onAddBtnMoseEnter: $options.sliceBtnMoseEnter,
    onAddBtnMoseLeave: $options.sliceBtnMoseLeave,
    onOnSlideChange: $options.onSlideChange
  }, {
    default: _withCtx(slotProps => [_createVNode(_component_swiper_five, {
      ref: `swiperfive_${slotProps.index}`,
      itemHeight: $data.imgHeight,
      dataArr: slotProps.item.items,
      onOnSlideChange: $options.onSlideFiveChange
    }, {
      default: _withCtx(slotp => [(_openBlock(), _createBlock(_resolveDynamicComponent($data.componentName[slotp.index]), {
        ref: `${$data.componentName[slotp.index]}_${slotProps.index}`,
        key: `${$data.componentName[slotp.index]}_${$data.randomLetters[slotp.index]}`,
        detail: slotProps,
        slotProps: slotp,
        activeIndex: $data.activeFive,
        imgHeight: $data.imgHeight,
        onToClose: $options.toClose,
        onToChangeSlideFive: $options.toChangeSlideFive
      }, null, 40, ["detail", "slotProps", "activeIndex", "imgHeight", "onToClose", "onToChangeSlideFive"]))]),
      _: 2
    }, 1032, ["itemHeight", "dataArr", "onOnSlideChange"])]),
    _: 1
  }, 8, ["dataArr", "products", "itemHeight", "hasBtn", "onAddBtnMoseEnter", "onAddBtnMoseLeave", "onOnSlideChange"])]);
}