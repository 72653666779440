import "core-js/modules/es.array.push.js";
export default {
  props: {
    imgHeight: {
      default: 1000
    },
    slotProps: {
      default() {
        return {};
      }
    },
    detail: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      timeOut: null,
      themeColor: '',
      isAddHover: false,
      isWhiteHoverNum: 0,
      isReduceHover: false,
      isPreSlideMose: false,
      isNextSlideMose: false,
      showMask: false,
      rotateDeg: ['1deg', '3deg', '-2deg', '4deg', '5deg', '-1deg']
    };
  },
  watch: {},
  computed: {},
  components: {},
  created() {},
  mounted() {},
  methods: {
    addBtnMoseEnter(type) {
      this.clearTime();
      if (type === 1) {
        this.showMask = true;
        this.timeOut = setTimeout(() => {
          this.isAddHover = true;
          ++this.isWhiteHoverNum;
        }, 100);
      } else {
        this.showMask = false;
        this.isReduceHover = true;
      }
    },
    addBtnMoseLeave(type) {
      this.rotateDeg.push(this.rotateDeg.shift());
      this.clearTime();
      if (type === 1) {
        this.isAddHover = false;
      } else {
        // this.timeOut = setTimeout(() => {
        //   this.showMask = true
        // }, 500)
        this.isReduceHover = false;
      }
    },
    sliceBtnMoseEnter(type) {
      console.log(type);
      console.log('slotProps', this.slotProps.index);
      if (type === 1) {
        this.isPreSlideMose = true;
      } else {
        this.isNextSlideMose = true;
      }
    },
    sliceBtnMoseLeave(type) {
      console.log('isNextSlideMose', this.isNextSlideMose);
      if (type === 1) {
        this.isPreSlideMose = false;
      } else {
        this.isNextSlideMose = false;
      }
    },
    toChangeSlideFive(index) {
      this.$emit('toChangeSlideFive', index);
    },
    clearTime() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
    }
  }
};