import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-34fc7743"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "com-swiperFive-box"
};
const _hoisted_2 = {
  class: "com-swiperFive-menu-div"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_swiper, {
    ref: "swiper",
    class: "com-swiperFive-swiper",
    modules: $setup.modules,
    effect: "fade",
    height: $props.itemHeight,
    fadeEffect: {
      crossFade: true
    },
    onSwiper: $options.onSwiper,
    onSlideChange: $options.onSlideChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataArr, (item, index) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: index
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
          item: item,
          index: index
        }, undefined, true)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 3
  }, 8, ["modules", "height", "onSwiper", "onSlideChange"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataArr, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(["com-swiperFive-menu-item", {
        'com-swiperFive-menu-item-active': $data.active == index
      }]),
      onClick: $event => $options.slideTo(index)
    }, _toDisplayString(item.title), 11, _hoisted_3);
  }), 128))])]);
}