/*
 * @author: weichuanjia
 * @Date: 2023-06-12 11:23:59
 */
import { createStore } from 'vuex'

const SET_LANG = 'SET_LANG'
const SET_MENU = 'SET_MENU'

export default createStore({
  state: {
    lang: 1,
    menu: 0
  },
  getters: {
  },
  mutations: {
    [SET_LANG] (state, lang) {
      state.lang = lang
    },
    [SET_MENU] (state, menu) {
      state.menu = menu
    }
  },
  actions: {
  },
  modules: {
  }
})
