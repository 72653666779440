import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3cf1e0dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "com-bannerSwiper-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_swiper_one = _resolveComponent("swiper-one");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_swiper_one, {
    dataArr: $data.dataArr
  }, {
    default: _withCtx(slotProps => [_createVNode(_component_van_image, {
      width: "100%",
      height: $data.imgHeight,
      fit: "cover",
      src: slotProps.item.src,
      onClick: $event => $options.toDetail(slotProps.item)
    }, null, 8, ["height", "src", "onClick"])]),
    _: 1
  }, 8, ["dataArr"])]);
}